import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { knowledgeArticlePropType } from 'lib/prop-types'
import Infoarticle from 'components/Contentful/Infoarticle'
import HowToStep from 'components/Contentful/HowToStep'
import FAQ from 'components/Contentful/FAQ'
import Link from 'components/Link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'

const howToStructuredData = howTo => {
  return JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'HowTo',
    name: howTo.title,
    description: howTo.description,
    step: howTo.content.map(step => {
      const image = step.image && step.image.url
      return {
        '@type': 'HowToStep',
        name: step.title,
        text: step.description,
        image: image,
      }
    }),
  })
}

const FAQStructuredData = content => {
  return JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'FAQPage',
    mainEntity: content.map(item => {
      return {
        '@type': 'Question',
        name: item.question,
        acceptedAnswer: { '@type': 'Answer', text: item.answer },
      }
    }),
  })
}

const NavigationLinks = ({ previousArticle, nextArticle }) => {
  return (
    <div className="flex justify-between bt--grey-light">
      <Link to={previousArticle} className="link link--default link--small mt2">
        <FontAwesomeIcon icon={faAngleLeft} className="link__icon--left" />
        Vorheriger Artikel
      </Link>
      <Link to={nextArticle} className="link link--default link--small mt2">
        Nächster Artikel
        <FontAwesomeIcon icon={faAngleRight} className="link__icon--right" />
      </Link>
    </div>
  )
}

const KnowledgeArticle = props => {
  const updatedAt = new Date(props.updatedAt).toLocaleDateString('de-DE', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  const { articleType, content } = props

  return (
    <div className="section section--padded-big">
      <div className="section__inner">
        <Helmet>
          <meta name="description" content={props.description} />
          <meta name="title" content={props.title} />
        </Helmet>

        <div className="article__date margin--b">
          Zuletzt geändert am {updatedAt}
        </div>
        <h2 className="headline headline--left">{props.title}</h2>
        <div className="article__description column--measure-medium">
          {props.description}
        </div>
        <hr className="seperator" />
        {articleType === 'Infoartikel' ? (
          <Infoarticle {...props} />
        ) : articleType === 'FAQ' ? (
          <Fragment>
            <Helmet>
              <script type="application/ld+json">
                {FAQStructuredData(content)}
              </script>
            </Helmet>
            {content.map((question, i) => (
              <FAQ key={`question-${i}`} {...question} />
            ))}
          </Fragment>
        ) : articleType === 'Anleitung' ? (
          <Fragment>
            <Helmet>
              <script type="application/ld+json">
                {howToStructuredData(props)}
              </script>
            </Helmet>
            <div className="article__instructions column--measure-wide center">
              {content.map((step, i) => (
                <HowToStep key={`step-${i}`} step={step} stepNumber={i} />
              ))}
            </div>
          </Fragment>
        ) : null}

        <NavigationLinks {...props} />
      </div>
    </div>
  )
}

KnowledgeArticle.propTypes = knowledgeArticlePropType
NavigationLinks.propTypes = knowledgeArticlePropType

export default KnowledgeArticle
