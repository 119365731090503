import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import contentfulUrlForLink from 'lib/contentful-url-for-link'
import { linkPropType, urlLocationPropType } from 'lib/prop-types'
import Link from 'components/Link'
import { navigate } from 'gatsby'

const SideNavigation = props => {
  const links = props.links.map(link => {
    return { ...link, url: contentfulUrlForLink(link) }
  })
  const currentUrl = props.location.pathname.replace(/\/*$/, '') // ignore trailing slashes
  const activeLink = links.find(link => link.url === currentUrl)

  return (
    <Fragment>
      <div className="side-nav__wrapper">
        <div className="side-nav">
          {links.map(link => {
            const { url } = link
            const isActive = url === currentUrl
            return (
              <Link
                key={`sideNavigation${url}`}
                to={url}
                className={`side-nav__item ${isActive ? 'active' : ''}`}
              >
                {link.label}
              </Link>
            )
          })}
        </div>
      </div>
      <div className="padding--v1 side-nav__mobile">
        <select
          onChange={e => {
            navigate(e.target.value)
          }}
          className="hm-select"
        >
          <option
            value=""
            selected={typeof activeLink === 'undefined'}
            disabled={true}
          >
            Menü
          </option>
          {links.map(link => {
            const { url } = link
            return (
              <option
                key={`sideNavigation${url}`}
                value={url}
                selected={currentUrl === url}
              >
                {link.label}
              </option>
            )
          })}
        </select>
      </div>
    </Fragment>
  )
}

SideNavigation.propTypes = {
  links: PropTypes.arrayOf(linkPropType),
  location: urlLocationPropType,
}

export default SideNavigation
