import React from 'react'
import { FAQPropType } from 'lib/prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'

const FAQ = props => {
  return (
    <div className="article__faq--item">
      <div className="article__faq--icon">
        <FontAwesomeIcon icon={faQuestion} />
      </div>
      <div className="article__faq--content">
        <h3
          dangerouslySetInnerHTML={{
            __html: props.question,
          }}
          className="mt0 mb1"
        />
        <div dangerouslySetInnerHTML={{ __html: props.answer }} />
      </div>
    </div>
  )
}

FAQ.propTypes = FAQPropType

export default FAQ
