import React from 'react'
import { mediaPropType } from 'lib/prop-types'

const Media = props => {
  const isVideo = props.file.contentType.startsWith('video')
  const isImage = props.file.contentType.startsWith('image')
  return (
    <div
      className={
        props.fullSize
          ? 'article__media--item item--full'
          : 'article__media--item item--half'
      }
    >
      {isVideo ? (
        <div className="article__media--video">
          <video
            src={props.file.url}
            alt={props.altText || props.description}
            controls
          />
        </div>
      ) : isImage ? (
        <div className="article__media--img">
          <img src={props.file.url} alt={props.altText || props.description} />
        </div>
      ) : null}
      <h3 className="article__media--title">{props.title}</h3>
      <div dangerouslySetInnerHTML={{ __html: props.description }} />
    </div>
  )
}

Media.propTypes = mediaPropType

export default Media
