import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import YouTubeLink from 'components/Contentful/YouTubeLink'
import Media from 'components/Contentful/Media'
import { articlePropType } from 'lib/prop-types'

const Infoarticle = props => {
  const article = props.content[0]

  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'Article',
    dateModified: article.updatedAt,
    headline: props.title,
    articleBody: article.text,
  }
  if (props.previewImage && props.previewImage.url) {
    structuredData.image = [props.previewImage.url]
  }

  return (
    <div className="article__content">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      {article.gallery ? (
        <div className="article__gallery">
          {article.gallery.map((item, i) => {
            return (
              <Fragment key={`gallery-item-${i}`}>
                {item.type === 'youTubeLink' ? (
                  <YouTubeLink {...item} />
                ) : item.type === 'media' ? (
                  <Media {...item} />
                ) : null}
              </Fragment>
            )
          })}
        </div>
      ) : null}
      <div
        dangerouslySetInnerHTML={{
          __html: article.text,
        }}
        className="article__text"
      />
    </div>
  )
}
Infoarticle.propTypes = articlePropType

export default Infoarticle
